import { _ } from "vue-underscore";
import Multiselect from 'vue-multiselect'
import Vue from 'vue';
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import ListItem from "@tiptap/extension-list-item";
import CharacterCount from "@tiptap/extension-character-count";
import { Editor, EditorContent } from "@tiptap/vue-2";
import MenuBar from "./MenuBar.vue";
import Image1 from "@tiptap/extension-image";
import History from "@tiptap/extension-history";
export default {
	data: () => ({
		seoCountry: {
			id: 0,
			url_slug: "",
			code: '',
			name: '',
			region_code: '',
			region_name: '',
			meta_title: "",
			meta_keywords: "",
			meta_description: "",
			og_tc_same_to_meta: false,
			head_scripts: "",
			body_scripts: "",
			logo_alt_text: '',
			logo_url: "",
			logoImage: "",
			body: "",
			faqs: [{ question: '', answer: '' }],
		},
		selectedRegion: {
			region_code: '',
			region_name: ''
		},
		selectedCountry: {
			code: '',
			name: ''
		},
		companyList: [{
			name: 'Acrux'
		}],
		editor: null,
		isFullScreen: false,
		loading: false,
		company_id: '',
		webUrl: process.env.VUE_APP_WEB_URL,
		countryList: [],
		imageFile: null,
		factsList: [],
		error: "",
		backUrl: "/seo-countries",
		regionsList: [],
	}),

	components: {
		Multiselect,
		EditorContent,
		MenuBar,
	},

	computed: {
		isSameOgTcValue() {
			return this.seoCountry.og_tc_same_to_meta ? 1 : 0;
		},
		getRules() {
			return this.seoCountry.og_tc_image ? "" : "required";
		},
		getDataTitle() {
			return this.imageName ? this.imageName : "Drag your image here";
		},
		wordCount() {
			if (this.seoCountry.post_body.trim() === "") {
				return 0;
			}
			return this.seoCountry.post_body.trim().split(/\s+/).length;
		},
		calculateReadingTime1() {
			const wordsPerMinute = 150;
			const minutes = Math.ceil(this.wordCount / wordsPerMinute);
			return minutes;
		},
		getcountry() {
			this.code = this.seoCountry.code;
			this.name = this.seoCountry.name;
			return this.code + '-' + this.name
		},
		getRegionCodeName() {
			this.code = this.seoCountry.region_code;
			this.name = this.seoCountry.region_name;
			return this.code + '-' + this.name
		}
	},
	watch: {
		"seoCountry.og_tc_same_to_meta": function (value) {
			if (value) {
				this.seoCountry.og_tc_title = this.seoCountry.meta_title;
				this.seoCountry.og_tc_description = this.seoCountry.meta_description;
			} else {
				this.seoCountry.og_tc_title = this.seoCountry.og_tc_title;
				this.seoCountry.og_tc_description = this.seoCountry.og_tc_description;
			}
		},
		company_id: {
			handler(value) {
				if (value) {
					this.selectedRegion = {};
					this.selectedCountry = {};
				} else {
					this.selectedRegion = {};
					this.selectedCountry = {};
				}
			}
		},
		selectedRegion: {
			handler(newVal) {
				if (newVal) {
					this.seoCountry.region_code = newVal.region_code;
					this.seoCountry.region_name = newVal.region_name;
				} else {
					this.selectedCountry = {};
				}
			},
			deep: true,
		},
		selectedCountry: {
			handler(newVal) {
				this.seoCountry.code = newVal.code;
				this.seoCountry.name = newVal.name;
			},
			deep: true,
		}
	},
	methods: {
		updateIsSameOgTcValue(event) {
			this.seoCountry.og_tc_same_to_meta = event.target.checked;
		},
		cloneItem(index) {
			const newItem = {
				question: '',
				answer: '',
			};
			this.seoCountry.faqs.push(newItem);
		},
		removeItem(index) {
			if (this.seoCountry.faqs.length > 1) {
				this.seoCountry.faqs.splice(index, 1);
			}
		},
		calculateReadingTime(wordCount) {
			const wordsPerMinute = 150;
			const minutes = Math.ceil(wordCount / wordsPerMinute);
			return minutes;
		},
		calculateReadingTimeLabel(wordCount) {
			const minutes = this.calculateReadingTime(wordCount);
			return minutes === 1 ? "minute" : "minutes";
		},
		toggleFullScreen() {
			this.isFullScreen = !this.isFullScreen;
			const editorElement = document.querySelector(".editor");
			if (editorElement) {
				if (this.isFullScreen) {
					editorElement.style.height = "100vh";
				} else {
					editorElement.style.height = "26rem";
				}
			}
		},
		value(value) {
			const isSame = this.editor.getHTML() === value;
			if (isSame) {
				return;
			}
			this.editor.commands.setContent(value, false);
		},
		initEditor() {
			this.seoCountry.body = "";

			this.editor = new Editor({
				content: this.seoCountry.body,
				extensions: [
					StarterKit.configure({
						history: false,
					}),
					Link.configure({
						openOnClick: false,
					}),
					CharacterCount,
					BulletList.configure({
						itemTypeName: "listItem",
						keepMarks: true,
						keepAttributes: true,
					}),
					ListItem,
					OrderedList,
					Image1,
					History,
				],
				onUpdate: () => {
					this.seoCountry.body = this.editor.getHTML();
					this.$emit("input", this.seoCountry);
				},
			});
		},
		resetForm() {
			this.$refs.observer.reset();
			this.seoCountry = {
				id: 0,
				name: "",
				description: "",
				title: "",
				region_id: "",
				page_title: "",
				url_slug: "",
				head_section: "",
				body_section: "",
				priority: "",
				logo_url: "",
			};
		},

		countryCreateOrUpdate() {
			let _vm = this;
			const com_id = _vm.company_id;
			let fd = new FormData();
			const airline = [];
			if (_vm.seoCountry.logo_url) {
				fd.append("logoImage", _vm.seoCountry.logo_url);
			}
			_vm.seoCountry.og_tc_same_to_meta = _vm.seoCountry.og_tc_same_to_meta ? 1 : 0;
			_vm.seoCountry.company_id = _vm.company_id;
			_vm.seoCountry.body = _vm.editor.getHTML();
			_vm.seoCountry.url_slug = _vm.seoCountry.url_slug.toLowerCase();

			Object.keys(_vm.seoCountry).forEach(key => {
				if (_vm.seoCountry[key] !== null) {
					if (key === 'faqs') {
						_vm.seoCountry.faqs.forEach((faq, index) => {
							fd.append(`faqs[${index}][question]`, faq.question);
							fd.append(`faqs[${index}][answer]`, faq.answer);
						});
					} else {
						fd.append(key, _vm.seoCountry[key]);
					}
				}
			});

			this.axios.post(`/seo/countries/` + com_id + `/update`, fd).then(function (response) {
				_vm.$router.push(_vm.backUrl);
			});
		},

		getQueryString() {
			let queryString = "?search=";
			return queryString;
		},
		getDetail() {
			let _vm = this;
			this.axios
				.get(`seo/countries/` + this.$route.params.company_id + `/` + this.$route.params.id)
				.then(function (response) {
					_vm.seoCountry = response.data.data;
					_vm.seoCountry.body = response.data.data.body;
					_vm.company_id = _vm.seoCountry.company_id;
					_vm.value(_vm.seoCountry.body);
					if (_vm.seoCountry.full_image_url && _vm.seoCountry.full_image_url.length > 0) {
						$("#imagePreview").css(
							"background-image",
							"url(" + _vm.seoCountry.full_image_url + "?date=" + Date.now() + ")"
						);
						$("#imagePreview").hide();
						$("#imagePreview").fadeIn(650);
					}
					if (_vm.seoCountry.full_icon_url && _vm.seoCountry.full_icon_url.length > 0) {
						$("#imagePreview1").css(
							"background-image",
							"url(" + _vm.seoCountry.full_icon_url + "?date=" + Date.now() + ")"
						);
						$("#imagePreview1").hide();
						$("#imagePreview1").fadeIn(650);
					}
				})
				.catch(function () { });
		},
		getRegion() {
			const _vm = this
			const com_id = _vm.company_id
			this.axios
				.get(`seo/regions/` + com_id + `/list`)
				.then(function (response) {
					_vm.regionsList = response.data.data
				})
				.catch(function () { })
		},
		onChangeCountry() {
			const _vm = this
			const selected = _vm.selectedRegion
			this.axios
				.get(`seo/countries/` + _vm.company_id + `/distinct-list/` + selected.region_code)
				.then(function (response) {
					_vm.countryList = response.data.data;
				})
				.catch(function () { })
		},
		selectImage(event) {
			let _vm = this;
			var input = event.target;
			if (input.files && input.files[0]) {
				_vm.seoCountry.logoImage = input.files[0];
				var reader = new FileReader();
				reader.onload = function (e) {
					$("#imagePreview").css(
						"background-image",
						"url(" + e.target.result + ")"
					);
					$("#imagePreview").hide();
					$("#imagePreview").fadeIn(650);
				};
				reader.readAsDataURL(input.files[0]);
			}
		},
		updateUrl(event) {

			if (event.code === 'Space') {
				const inputText = event.target.value.replace(/\s{1,}/g, '-').toLowerCase();
				if (this.seoCountry.url_slug.length > 1 && this.seoCountry.url_slug[this.seoCountry.url_slug.length - 2] === '-') {
					this.seoCountry.url_slug = inputText;
				} else {
					this.seoCountry.url_slug = inputText;
				}
			}

		},
		getCompany() {
			let _vm = this;
			this.axios
				.get("company-list")
				.then(function (response) {
					_vm.companyList = response.data.data;
					_vm.company_id = _vm.company_id ? _vm.company_id : _vm.companyList[0].id;
					_vm.getRegion();
				})
				.catch(function () { });
		},
	},

	mounted() {

		if (this.$route.params.id !== undefined && this.$route.params.id !== "") {
			this.getDetail();
		}

		this.getCompany();
		this.initEditor();
	}
};
